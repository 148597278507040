import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { UnreachableCaseError } from '@orus.eu/error'
import {
  Avatar,
  Button,
  HorizontalLogo,
  OtpInput,
  PersistentNotification,
  Spinner,
  Text,
  TextFieldFormField,
  colorTokens,
  colors,
  mobileMediaQuery,
  spacing,
  useAsyncCallback,
  useTranslate,
} from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { memo, useCallback, useEffect, useState, type ChangeEvent, type FormEvent } from 'react'
import { trpc, trpcReact } from '../../../client'
import { useNavigateToSubscriptionFunnel } from '../../../lib/hooks/use-navigate-to-subscription-funnel'
import { useStartSelfOnboarding } from '../../../lib/hooks/use-start-self-onboarding'
import { openChat } from '../../../lib/hubspot-util'
import { sessionManager } from '../../../lib/session'

type OtpStageProps = {
  email: string
  otpId: string
  otpExpirationMs: number
}

const LoginPage = memo(function LoginPage(): JSX.Element {
  const searchParams = useSearch({ strict: false })
  const emailUrlParam = searchParams.email
  const redirectParam = searchParams.redirect
  const continueSubscriptionIdParam = searchParams.continueSubscriptionId
  const navigate = useNavigate()
  const navigateToSubscriptionFunnel = useNavigateToSubscriptionFunnel()

  const [stage, setStage] = useState<{ type: 'email'; email: string | undefined } | ({ type: 'otp' } & OtpStageProps)>({
    type: 'email',
    email: emailUrlParam,
  })

  const goToOtpStage = useCallback((props: OtpStageProps) => {
    setStage({ type: 'otp', ...props })
  }, [])

  const goToEmailStage = useCallback((email: string) => {
    setStage({ type: 'email', email })
  }, [])

  const handleSuccess = useAsyncCallback(async () => {
    await sessionManager.refreshSession()

    if (redirectParam) {
      window.location.href = redirectParam
      return
    }

    if (continueSubscriptionIdParam) {
      const continueSubscriptionResult =
        await trpc.subscriptions.continueSubscriptionAsCustomer.mutate(continueSubscriptionIdParam)

      if (isFailure(continueSubscriptionResult)) {
        switch (continueSubscriptionResult.problem) {
          case 'contract-already-signed':
            void navigate({ to: '/', replace: true })
            return
          default:
            throw new UnreachableCaseError(continueSubscriptionResult.problem)
        }
      }
      navigateToSubscriptionFunnel(continueSubscriptionIdParam)
      return
    }

    void navigate({ to: '/', replace: true })
  }, [redirectParam, continueSubscriptionIdParam, navigate, navigateToSubscriptionFunnel])

  if (stage.type === 'email') {
    return <EmailStage initialEmail={stage.email} goToOtpStage={goToOtpStage} />
  }

  return <OtpStage {...stage} goToEmailStage={goToEmailStage} handleSuccess={handleSuccess} />
})

export default LoginPage

const EmailStage = memo<{ initialEmail: string | undefined; goToOtpStage: (props: OtpStageProps) => void }>(
  function EmailStage({ initialEmail, goToOtpStage }): JSX.Element {
    const [email, setEmail] = useState(initialEmail ?? '')
    const generateAndSendLoginOtpMutation = trpcReact.login.generateAndSendLoginOtp.useMutation()

    const startSelfOnboarding = useStartSelfOnboarding()

    const handleEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value)
    }, [])

    const handleEmailSubmit = useCallback(
      (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        generateAndSendLoginOtpMutation.mutate({ email })
      },
      [email, generateAndSendLoginOtpMutation],
    )

    useEffect(() => {
      if (generateAndSendLoginOtpMutation.data?.type === 'success') {
        goToOtpStage({
          email,
          otpId: generateAndSendLoginOtpMutation.data.output.otpId,
          otpExpirationMs: generateAndSendLoginOtpMutation.data.output.expirationMs,
        })
      }
    }, [email, generateAndSendLoginOtpMutation.data, goToOtpStage])

    if (
      generateAndSendLoginOtpMutation.data?.type === 'failure' &&
      generateAndSendLoginOtpMutation.data.problem === 'already-logged-in'
    ) {
      document.location.reload()
      return <></>
    }

    return (
      <Container>
        <HorizontalLogo color={colors.blue[900]} width={90} />
        <Title variant="h4">Me connecter</Title>

        {generateAndSendLoginOtpMutation.data?.type === 'failure' &&
        generateAndSendLoginOtpMutation.data.problem === 'too-many-otp' ? (
          <PersistentNotification
            variant="warning"
            title="Blocage de sécurité"
            css={css`
              margin-top: ${spacing[60]};
            `}
          >
            Nous avons détecté plusieurs tentatives infructueuses de connexion à votre compte. Pour des raisons de
            sécurité, nous en avons temporairement bloqué l’accès.
            <br />
            <br />
            Rassurez-vous, il s’agit d’une mesure de précaution, l’envoi d’un nouveau code sera possible d’ici 60
            minutes.
          </PersistentNotification>
        ) : null}

        <Form onSubmit={handleEmailSubmit}>
          <TextFieldFormField
            size="large"
            label="Email"
            type="email"
            autoFocus
            placeholder="julien.durantier@mail.com"
            value={email}
            onChange={handleEmailChange}
            disabled={generateAndSendLoginOtpMutation.isPending}
          />

          <Button
            variant="primary"
            size="large"
            type="submit"
            fullWidth
            isLoading={generateAndSendLoginOtpMutation.isPending}
            disabled={!email || generateAndSendLoginOtpMutation.isPending}
          >
            Se connecter
          </Button>
        </Form>

        <Title variant="body1Medium">Pas encore de compte Orus ?</Title>

        <StyledButton variant="secondary" size="large" fullWidth onClick={() => startSelfOnboarding()}>
          Assurer mon activité
        </StyledButton>
      </Container>
    )
  },
)

const OtpStage = memo<{
  email: string
  otpId: string
  otpExpirationMs: number
  goToEmailStage: (email: string) => void
  handleSuccess: () => void
}>(function OtpStage({ email, otpId, otpExpirationMs, goToEmailStage, handleSuccess }): JSX.Element {
  const translate = useTranslate()
  const attemptLoginMutation = trpcReact.login.attemptLogin.useMutation()

  const handleOtpComplete = useCallback(
    (otp: string) => {
      attemptLoginMutation.mutate({ otpId, otpCode: otp })
    },
    [attemptLoginMutation, otpId],
  )

  const handleOtpChange = useCallback(() => {
    attemptLoginMutation.reset()
  }, [attemptLoginMutation])

  useEffect(() => {
    if (attemptLoginMutation.data?.type === 'success') {
      handleSuccess()
    }
  }, [attemptLoginMutation.data, handleSuccess])

  if (attemptLoginMutation.data?.type === 'failure' && attemptLoginMutation.data.problem === 'already-logged-in') {
    document.location.reload()
    return <></>
  }

  return (
    <Container>
      <HorizontalLogo color={colorTokens['color-fg-base']} width={90} />
      <Title variant="h4">Code de connexion</Title>
      <Subtitle variant="body2">
        Renseignez le code envoyé à l’adresse email{' '}
        <Text element="span" variant="body2Medium">
          {email}
        </Text>
      </Subtitle>

      <StyledOtpInput
        state={
          attemptLoginMutation.data?.type === 'failure'
            ? 'error'
            : attemptLoginMutation.isPending
              ? 'loading'
              : 'neutral'
        }
        autoFocus
        onComplete={handleOtpComplete}
        onChange={handleOtpChange}
      />

      <EventBox>
        {!attemptLoginMutation.data ? (
          <Caption variant="caption">Expiration du code après un délai de {otpExpirationMs / 60_000} minutes.</Caption>
        ) : null}

        {attemptLoginMutation.isPending ? (
          <Spinner
            size="50"
            css={css`
              align-self: center;
            `}
          />
        ) : null}

        {attemptLoginMutation.data?.type === 'failure' && attemptLoginMutation.data.problem === 'wrong-code' ? (
          <PersistentNotification variant="danger" title="Code invalide">
            Le code est invalide, merci de vérifier votre saisie.
          </PersistentNotification>
        ) : null}

        {attemptLoginMutation.data?.type === 'failure' && attemptLoginMutation.data.problem === 'expired' ? (
          <PersistentNotification
            variant="danger"
            title="Code expiré"
            actionButton={{
              label: 'Renvoyer un code',
              onClick: () => goToEmailStage(email),
            }}
          >
            Le délai de validité du code de connexion a été dépassé.
          </PersistentNotification>
        ) : null}

        {attemptLoginMutation.data?.type === 'failure' && attemptLoginMutation.data.problem === 'too-many-attempts' ? (
          <PersistentNotification
            variant="danger"
            title="Code expiré"
            actionButton={{
              label: 'Renvoyer un code',
              onClick: () => goToEmailStage(email),
            }}
          >
            Vous avez dépassé le nombre de tentatives autorisées pour ce code.
          </PersistentNotification>
        ) : null}

        {attemptLoginMutation.data?.type === 'failure' && attemptLoginMutation.data.problem === 'no-account' ? (
          <PersistentNotification
            variant="danger"
            title="Pas de compte"
            actionButton={{
              label: translate('back'),
              onClick: () => goToEmailStage(''),
            }}
          >
            Nous n’avons pas de compte client associé à l’adresse {email}.
          </PersistentNotification>
        ) : null}
      </EventBox>

      <Hint variant="caption">
        Conseil : vérifiez votre boîte de réception et le dossier des courriers indésirables
      </Hint>

      <StyledButton
        variant="text"
        size="small"
        fullWidth
        onClick={openChat}
        avatarPosition="left"
        avatar={
          <Avatar
            icon="comment-question-duotone"
            color={colorTokens['color-fg-base-active-inverse']}
            secondaryColor={colorTokens['color-fg-base-active-inverse']}
          />
        }
      >
        Demander de l’aide
      </StyledButton>
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 400px;
  height: 100vh;
  margin: auto;
  overflow: auto;

  ${mobileMediaQuery} {
    justify-content: flex-start;
    width: auto;
    padding: ${spacing[60]};
  }
`

const Title = styled(Text)`
  margin-top: ${spacing[100]};
`

const Subtitle = styled(Text)`
  margin-top: ${spacing[40]};

  ${mobileMediaQuery} {
    margin-top: ${spacing[30]};
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${spacing[60]};

  margin-top: ${spacing[60]};

  ${mobileMediaQuery} {
    gap: ${spacing[70]};
    margin-top: ${spacing[70]};
  }
`

const StyledButton = styled(Button)`
  margin-top: ${spacing[60]};

  ${mobileMediaQuery} {
    margin-top: ${spacing[70]};
  }
`

const StyledOtpInput = styled(OtpInput)`
  align-self: center;
  margin-top: ${spacing[60]};

  ${mobileMediaQuery} {
    margin-top: ${spacing[70]};
  }
`

const EventBox = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 112px;

  margin-top: ${spacing[60]};

  ${mobileMediaQuery} {
    margin-top: ${spacing[70]};
  }
`

const Caption = styled(Text)`
  text-align: center;
`

const Hint = styled(Text)`
  margin-top: ${spacing[100]};

  text-align: center;
`
